function config(){
	return {
		webname:'培训学校管理系统',
		//url:'http://192.168.1.114:8990',
		url:'http://stu.fyzzn.com',
		frontUrl:'http://training.fyzzn.com/',
		WebSocket:'http://127.0.0.1:2130', 
	}
}

function doMain(){return this.config().url+'/admin';}
function login(){return this.doMain() + '/admins/login';}
function register(){return this.doMain() + '/admins/register';}
function edit_oneself(){return this.doMain() + '/admins/edit_oneself';}
//function userIndex(){return this.doMain() + '/admins/index';}
function upload(){return this.doMain() + '/upload/add';}
export default{
	doMain,
	login,
	register,
	edit_oneself,
	upload,
	config, 
}